import React, { Component } from 'react';
import './builder.css';
import './angle-picker.css';
import './tabs.css';
import './canvas.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../Theme/theme_default';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconCart   from '@material-ui/icons/ShoppingCart'
import Iconinfo   from '@material-ui/icons/Info'


import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import icon00 from './img/glove00.svg';
import icon01 from './img/glove01.svg';
import icon02 from './img/glove02.svg';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import VerticalTabs from './LayerPicker/Layered'


/*const Layerspicker = _ => {

  const [value, setValue] = React.useState(storage["active.layer"]);
  const Get = val => GetInstructions(val);
  const [force, Setforce] = React.useState(false)

  if(force){
   filteroptions(value)
   console.info(value)
  }

  const handleChange = (event, newValue) => {
    storage["active.layer"] = event.currentTarget.name
    setValue(newValue);
    if(!force)Setforce(true)
  } 

  const Renderlayers = props => {
    const layers = props.layers;
    const listItems = layers.map((i) => {
      if(i.lyr){
       return <Tab name={i.lyr} className="color-module" key={i.id} value={i.id} label={i.name} />
      }else{
       return <Tab name={i.id} className="color-module" key={i.id} value={i.id} label={i.name} />
      }
    });
    return (
      <MuiThemeProvider theme={theme}>
        <Tabs className="Tabs" value={value} indicatorColor="primary" textColor="primary" onChange={handleChange} variant="scrollable" scrollButtons="on"> 
        {listItems}
        </Tabs>
      </MuiThemeProvider>
    );    
  }

  return <Renderlayers layers={GetProduct(Get("Product").default)["Layers"]} />;
}*/


const Modangle = _ => {

  return (
    <div id="angle-picker">

      <div>

        <div className="iconpicker" value='angle1' style={{backgroundImage: `url(${icon00})`}} data-selected="false"></div>

      </div>
      <div>

        <div className="iconpicker" value='angle2' style={{backgroundImage: `url(${icon01})`}} data-selected="false"></div>

      </div>
      <div>

        <div className="iconpicker" value='angle3' style={{backgroundImage: `url(${icon02})`}} data-selected="false"></div>

      </div>

    </div>
  );


}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function FullScreenDialog() {

  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div className="ButtonSummary">
      <Button onClick={handleClickOpen}><Iconinfo /> Summary</Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar style={{position:'relative'}}>
          <Toolbar style={{color:'#fff'}}>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography color="inherit" variant="h6" style={{flex:'1'}}>
              Summary
            </Typography>
            <Button color="inherit" onClick={handleClose}>
              Checkout
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Opcion 1" secondary="Respuesta1" />
          </ListItem>
          <Divider />
          <ListItem button>
            <ListItemText primary="Opcion 2" secondary="Respuesta2" />
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}


class Builder extends Component {

  render() {

    const html = (
     <div className="Site-content">    

     <Hidden only={['xs', 'sm']}>
      <div className="main_otherproducts">
        <div className="card">
         <Modangle />
        </div>
      </div>
     </Hidden>

      <div className="main_builder">
       <div className="card" /*style={{backgroundImage: `url(${cardimg})`}}*/>
        <div className="Content_canvas">
          <div id="Loader" className="off">
           <div className="Loader-wrapper">
             <div className="spinner"></div>
            </div>
          </div>
          <div id="ctl-canvas"></div>
        </div>        
       </div>     
      </div>
      
     <Hidden only={['xs', 'sm']}>
      <div className="main_pucharse">
       <MuiThemeProvider theme={theme}>
        <div className="card">
           <div data-name="ProductName">ProVN1</div>
           <div data-name="ProductPrice">$ 500.00</div>
           <div className="ButtonPucharse">
            <Button variant="contained"><IconCart style={{fontSize:'1rem'}} /> Add to Cart</Button>
           </div> 
           <FullScreenDialog />
        </div>
       </MuiThemeProvider>

       <div className="card">
           <VerticalTabs />
       </div>

      </div>
     </Hidden>

     </div>     
    );


    return html
  }
}

export default Builder;
