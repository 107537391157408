import './spinner.css'

  const spinner = (status,sppnner) => {
     let spinner = document.getElementById(sppnner);
     let canvas = document.getElementById("ctl-canvas");
     switch(status){
       case"on":
        switch(sppnner){
          case"Loader":           
           canvas.classList.add("canvas_anim");
          break;
          default:break;         
        }
        spinner.classList.remove("off");
        console.log("spinner Iniciado")         
       break;
       case"off":
        switch(sppnner){
          case"Loader":           
           canvas.classList.remove("canvas_anim");
          break;
          default:break;         
        }       
        spinner.classList.add("off");
        console.log("spinner Finalizado")
       break;
       default:
       break;
     }
   }

export default spinner;   