import _ from 'lodash'
import {GetInstructions,GetProduct} from '../assistants'
import {Modcolor} from './default'
import {ImgLoad} from '../../Canvas/ImagesLoad'


const rendercolors = async (i,bass) => {
  let cnvs = document.createElement("div");   
  let oo;  
  oo = await (cnvs.className = i.class);   
  if(i.default){   
   oo = await ImgLoad(i.image);
   oo = await cnvs.appendChild(oo)   
   oo = await cnvs.setAttribute("value", i.default);       
  }else{
   oo = await cnvs.setAttribute("value", i.name);
   oo = await (cnvs.style["background-color"] = i.color);      
  }
  oo = await rendericon(cnvs)
  oo = await (cnvs.name = i.name);
  oo = await cnvs.setAttribute("data-selected", false);
  oo = await bass.appendChild(cnvs)

  return oo
};

const rendericon = e => {

  let div = document.createElement("span");
  div.className = 'activeIcon';
  let div1 = document.createElement("span");
  div.appendChild(div1);

  e.appendChild(div)
}

const findlayer = (value,arr) => _.find(arr, (i) => {
   return i.id === value
});

const Insertactivelayer = (value) => {
  return new Promise(resolve =>  {
   GetInstructions("lyract").default = value;
   resolve()
  });
}

const filteroptions = async value => {

  let bass = document.getElementById("scrollmenu");
  const Get = val => GetInstructions(val);  
  console.log('dffgdfgdfg' + bass)
  
  if(bass){

    while(bass.firstChild){    
     if(bass.firstChild){
      bass.removeChild(bass.firstChild)
     }
    }
    let Product = GetProduct(Get("Product").default)
    let pass = await findlayer(value,Product["Layers"])
    let pross = await Promise.all(Product[pass.selector].map(cnf => rendercolors(cnf,bass)))
    pross = await Insertactivelayer(pass.selector)
    pross = await Modcolor()

    return pross;
  }


}

export default filteroptions;