import React, { Component } from 'react';
import './footer.css'

class Footer extends Component {
  render() {
    return ( 
 	  <div className="pages_footer">	
      <div>
       PlayPro 2020
      </div>
     </div> 
    );
  }
}

export default Footer;