import GlovesData from '../Data/data.json';
import Instructions from '../Data/Instructions.json';
import tmpcreateCanvas from '../Canvas/TmpCreateCanvas';
import createCanvas from '../Canvas/CreateCanvas';
import _ from 'lodash';


  const GetInstructions = Instruction => _.find(Instructions, (i) => {
   return i.id === Instruction
  }); 
 
  const GetProduct = Product => _.find(GlovesData, (p) => {
    return p.Product === Product
  });
 
  const GetLayer = (layer,Data) => _.find(Data, (l) => {
    return l.layer === layer
  });  

  const Size = cc => {

   let X = cc.X;  
   let Y = cc.Y;
 
   return {X,Y}    
  };

  const Getordens = lyr => {

   const Get     = val => GetInstructions(val); 

   let conf    = GetProduct(Get("Product").default);
   let reduce  = conf["Angles"][Get("Angle").default];

   if (lyr) {
    let getconf = GetLayer(lyr,reduce["layers"]);
    let cc      = reduce["conf"];
 
    let url     = cc.imgurl + getconf.img +".png";
    let urlbss  = cc.imgurl + cc[getconf.Type] +".png";
 
    let size    = Size(cc)
    let ctx     = createCanvas(getconf.layer,size,getconf.geo)
    let tmpCtx  = tmpcreateCanvas(size);     
    return {conf,getconf,cc,url,urlbss,ctx,tmpCtx,size}
   }else{

    return {conf,reduce}
    
   }

  }

  export {GetInstructions,GetProduct,GetLayer,Getordens}