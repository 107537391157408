import _ from 'lodash'
import {GetInstructions,GetProduct} from '../assistants'
import {Modcolor} from './default'


const renderlayers = async (i,bass) => {
  let cnvs = document.createElement("div");   
  let oo;  
  oo = await (cnvs.className = i.class);   

   oo = await cnvs.setAttribute("value", i.id);  
   
   if(i.lyr){
    oo = await (cnvs.name = i.lyr);  
   }else{
    oo = await (cnvs.name = i.id);  
   }   
  
  oo = await rendericon(cnvs);
  oo = await cnvs.setAttribute("data-selected", false);
  oo = await (cnvs.innerText = i.name);
  oo = await bass.appendChild(cnvs)

  return oo
};

const rendericon = e => {

  let div = document.createElement("span");
  div.className = 'activeIcon';
  let div1 = document.createElement("span");
  div.appendChild(div1);

  e.appendChild(div)
}

const findlayer = (value,arr) => _.find(arr, (i) => {
   return i.id === value;
});


const renderlayersmenu = async value => {

  let bass = document.getElementById("scrollayermenu");
  const Get = val => GetInstructions(val);  
  
  if(bass){

    while(bass.firstChild){    
      if(bass.firstChild){
       bass.removeChild(bass.firstChild)
      }
    }
    let Product = GetProduct(Get("Product").default)
    let pass = await findlayer(value,Product["Layers"])
    console.log(pass)
    let pross = await Promise.all(Product["Layers"].map(cnf => renderlayers(cnf,bass)))
    pross = await Modcolor()

    return pross;
  }


}

export default renderlayersmenu;
