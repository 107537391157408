import React, { Component } from 'react';
import './Home.css';
import HorizontalLinearStepper from '../../Builder/Selector/Selector';

class Home extends Component {
  render() {
    return (
     <div className="Home">
       <HorizontalLinearStepper />
     </div>
    );
  }
}

export default Home;
