import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import {draw} from '../initrender';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import './footer.css';
import './colorpicker.css';




class Footer_Builder extends Component {
  render() {  
   const html = (
    <div id="footer">
       <div className="colorIndicator">
        <h3>Soy un informador</h3>
       </div>
       <div className="cors_wrap">
        <IconButton color="default" id="icon_Left">  
         <KeyboardArrowLeft />
          </IconButton>
           <div id="scrollmenu"></div>
          <IconButton color="default" id="icon_Right">  
         <KeyboardArrowRight />
        </IconButton>
       </div>           
     </div> 
    );
   
  
    draw()

   return html
  }
}
export default Footer_Builder;