import _ from 'lodash'

  const Getlayer = (layer,data) => _.find(data, (v) => {
    return v.name === layer
  });

  const createCanvas = (layer,Size,Geo) => {

    let X     = Size.X
    let Y     = Size.Y
    const data = document.getElementById("ctl-canvas").getElementsByTagName("canvas");
    const Gl  = Getlayer(layer,data)
  
    if (Gl) {
  
      if (!(Gl.height === Y)) {
        Gl.height = Y;
      }

      if (!(Gl.width === X)) {
        Gl.width = X;
      }
  
      if(!(Gl["z-index"] === Geo)){
        Gl.style["z-index"] = Geo;
      }

      if((Gl.getAttribute("data-active") === "false")){
        Gl.setAttribute("data-active", true)
      }      

      return Gl.getContext("2d")
  
    } else {
      var cnvs = document.createElement("canvas");  
      cnvs.name = layer;
      cnvs.setAttribute("data-active", true)
      cnvs.style["z-index"] = Geo;    
      cnvs.width = X;
      cnvs.height = Y;
      document.getElementById("ctl-canvas").appendChild(cnvs);
      return cnvs.getContext("2d")
    }

  }

export default createCanvas;