
import {GetInstructions,Getordens} from '../Builder/assistants'
import drawImage from './drawImage'
import nonrenderdraw from './NonRenderDraw'
import renderTXT from './RenderTXT'
import {ImgLoad} from './ImagesLoad'
import {makeUrl,suplier,supliernon,suplierweb} from '../Builder/workes'
import ProviderDefaultColor from './GetColorDefault'



  
  const render = async (cnf,other) => {


    let rndr;
    switch(cnf.Type){
      case("norender"):
        let Get1 = supliernon(cnf,other)
        if (Get1) {
          rndr = await ImgLoad(Get1.shadow)
          rndr = await nonrenderdraw(cnf.layer,rndr,Get1.tmpCtx,Get1.size,Get1.ctx,cnf.Type)
          console.info(rndr)          
        }
      break;
      case"renderweb":

        let Getweb = cnf[GetInstructions("Web").default];
        if(Getweb){
          let color;
          if (Getweb.Inslt) {color = ProviderDefaultColor(Getweb.Inslt)}
          let Get0 = suplierweb(cnf,other,Getweb)
          let imagebss = await ImgLoad(makeUrl(other.imgurl,Getweb.bass))
          let imageshw = await ImgLoad(makeUrl(other.imgurl,Getweb.img))
  
          rndr = await drawImage(Getweb.layer,imagebss,imageshw,Get0.tmpCtx,Get0.size,Get0.ctx,cnf.Type,color) 
          console.info(rndr) 
        }        

      break;
      case"renderflag":

        let Getflag = cnf[GetInstructions("Flag").default]
        let Getflg = supliernon(Getflag,other)      
        console.log(Getflg)

        if (Getflg) {
          console.info(Getflg)
          let flag = await ImgLoad(Getflg.shadow)
          rndr = await nonrenderdraw(Getflag.layer,flag,Getflg.tmpCtx,Getflg.size,Getflg.ctx,Getflag.Type)
          console.info(rndr)             
        }
   


      break;
      case"rendertext":

      let rendertxtv1 = supliernon(cnf,other)
      
        rndr = null;
        rndr = await renderTXT(cnf.layer,rendertxtv1.size,rendertxtv1.ctx,cnf.Type)
        console.info(rndr)          
      

        console.log("RenderTX") 

      break;      
      default:
        let Get = suplier(cnf,other)
        let imagebase = await ImgLoad(Get.base)
        let imageshadow = await ImgLoad(Get.shadow)
        rndr = await drawImage(cnf.layer,imagebase,imageshadow,Get.tmpCtx,Get.size,Get.ctx,cnf.Type)
        console.log(rndr)
      break;
    }
    return rndr

  }

export default render;  