
  const nonrenderdraw = async (lyr,imagebase,tmpCtx,size,ctx,type) => {
    let rr;
    rr = await ctx.clearRect(0,0,size.X,size.Y);
    rr = await ctx.drawImage(imagebase, 0,0, size.X, size.Y);
    rr = await type + ":" +[lyr];
  
    return rr
  }

export default nonrenderdraw;