import React, { Component } from 'react';
import {Link}                 from "react-router-dom";
import Toolbar              from '@material-ui/core/Toolbar';
import Button               from '@material-ui/core/Button';
import IconButton           from '@material-ui/core/IconButton';
import MenuIcon             from '@material-ui/icons/Menu';
import User                 from '@material-ui/icons/Person';
import Drawer               from '@material-ui/core/Drawer';
import Logo                 from '../logo.png';
import './Sidebar.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../Theme/theme_default';
import Hidden from '@material-ui/core/Hidden';

class Menu extends Component {

  state = {
    left: false
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  logoo = (manager) => {
    let background = {
      backgroundImage: `url(${Logo})`
    }
    const html = (
       <div className="control">
        <Link to="/" className={manager}>
          <div style={background} className="logo"></div>
        </Link>                
       </div> 
    )
    return html
  }

  BtnBuiler = () => {

    let css = {
      margin:'0.2em',   
    } 
    const button = (
      <Button style={css} component={Link} to="/Builder" variant="outlined">Builder</Button>
    )

    return button
  }

  render() {

    const html = (
      <div className="Sidebar">      
       <Toolbar className="menu">
         {this.logoo("logo_bar")}

        <MuiThemeProvider theme={theme}>

         <Hidden only={['xs', 'sm']}>
          {this.BtnBuiler()} 
         </Hidden>

         <Hidden only={['md', 'lg' , 'xl']}>
          {this.BtnBuiler()}    
         </Hidden> 

        </MuiThemeProvider>              
       </Toolbar>
        <div id="bordered"></div>
      </div>
    );

    return html
  }
}

export default Menu;