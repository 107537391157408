
import spinner from './Loader/Spinner/spinner';
import {GetInstructions} from './assistants'
import filteroptions from './ButtonsModColor/Create_Colors'
import renderlayersmenu from './ButtonsModColor/Create_layers'
import Corslife from './ButtonsModColor/Cors_Life'
import {storage} from './ButtonsModColor/default'
import _ from 'lodash'
import {GetInfo} from './workes'
import {datacenter} from '../Data/Datacenter'
import render from '../Canvas/Render'



  const findlayer = (lyr,data) => _.find(data, (v) => {
    return v.layer === lyr
  });

  const processlayer = (arr,arr1) => _.map(arr, (i) => {
    let layer = findlayer(i.name,arr1)
    if(!layer){
      i.setAttribute("data-active", false)
    }
  });

  const ClearLayers = arr => {
    let ccv = document.getElementById("ctl-canvas");
    let layers = ccv.getElementsByTagName("canvas");
    processlayer(layers,arr)

    return arr
  }

  const draw = async _ => {

    let rr;
    rr = await spinner("on","pre")
    rr = await GetInfo()
    rr = await Promise.all(rr.layers.map(cnf => render(cnf,rr.conf)))
    rr = await filteroptions(storage["active.layer"])
    rr = await Corslife()    
    rr = await spinner("off","pre")

    return rr

  }

  const Insertangle = angle => {
    return new Promise(resolve =>  {
     GetInstructions("Angle").default = angle;
     console.info("Se inserto el angulo")
     resolve()
    });
  }


  const Anglemod = async Angle => {       

    let Angles = await GetInstructions("Angle").default;
    let rnd;

    switch(Angles){
      case(Angle):
       console.log(Angles + " is Running")
      break;
      default:

       rnd = await spinner("on","Loader")
       rnd = await Insertangle(Angle)
       rnd = await GetInfo()       
       rnd = await ClearLayers(rnd)
       rnd = await Promise.all(rnd.layers.map(cnf => render(cnf,rnd.conf)))
       rnd = await spinner("off","Loader")

       rnd = await console.info(datacenter)

      break;
    }

    return rnd;

  }

  export {draw,Anglemod}
