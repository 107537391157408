  import _ from 'lodash'
  import {GetInstructions} from '../assistants'
  import {colormod,webmod,flagmod} from '../ColorMod/default'
  import filteroptions from '../ButtonsModColor/Create_Colors'
  import {Anglemod} from '../initrender';

  const storage = {
    "active.layer" : "SB"
  }

  const fiind = (Instruction,arr) => _.find(arr, (i) => {
    return i.getAttribute("value") === Instruction
  });

  const map_remove = (arr) => _.map(arr, (i) => {
    i.setAttribute("data-selected", "false")
  });


  const angle_mod = (arr) => _.map(arr, i => i.addEventListener("click", _ => {
    Anglemod(i.getAttribute("value"))

    map_remove(arr);
    i.setAttribute("data-selected", "true")    
  })); 


  const map_layers = (arr) => _.map(arr, i => i.addEventListener("click", _ => {
    

      console.log("one " + storage["active.layer"])

      if( (storage["active.layer"]) !== (i.getAttribute("value")) ){            
         storage["active.layer"] = i.name;
         filteroptions(i.getAttribute("value"));
         map_remove(arr);  
         i.setAttribute("data-selected", "true")
         i.scrollIntoView({ inline: 'center' });  
         console.log("two " + storage["active.layer"])      
      }
    

    
  })); 

  const map_color = (arr) => _.map(arr, i => {
    i.addEventListener("click", _ => {
      colormod(i.getAttribute("value"),storage["active.layer"])
      map_remove(arr);

      i.setAttribute("data-selected", "true")
      i.scrollIntoView({ inline: 'center' });
    });
  }); 

  const map_web = (arr) => _.map(arr, i => {
    i.addEventListener("click", _ => {
      webmod(i.getAttribute("value"),storage["active.layer"])
      map_remove(arr);

      i.setAttribute("data-selected", "true");
      i.scrollIntoView({ inline: 'center' });
    });
  });     

  const map_flag = (arr) => _.map(arr, i => {
    i.addEventListener("click", _ => {
      flagmod(i.getAttribute("value"),storage["active.layer"])
      map_remove(arr);

      i.setAttribute("data-selected", "true")
      i.scrollIntoView({ inline: 'center' });
    });
  });   


  const layerprocess = _ => {

  }

  const Modcolor = _ => {

    let scroll = document.getElementById("scrollmenu");
    const getbuttonscors = scroll.getElementsByClassName("cormod");    
    const getbuttonsweb = scroll.getElementsByClassName("webmod");    
    const getbuttonsflag = scroll.getElementsByClassName("flagmod");
    let anglemod1 = document.getElementById("angle-picker"); 
    const anglemod = anglemod1.getElementsByClassName("iconpicker"); 


    if(anglemod.length >= 1){
      let llk = fiind(GetInstructions("Angle").default,anglemod);
      if(llk){
        llk.setAttribute("data-selected", "true");
      }    
      angle_mod(anglemod);
    }         

    if(getbuttonscors.length >= 1){
      let ss = fiind(GetInstructions(storage["active.layer"]).color,getbuttonscors)
      if(ss){
        ss.setAttribute("data-selected", "true");
        ss.scrollIntoView({ inline: 'center' });
      }      
      map_color(getbuttonscors)
    }

    if(getbuttonsweb.length >= 1){
      let aa = fiind(GetInstructions(storage["active.layer"]).default,getbuttonsweb)
      if(aa){
        aa.setAttribute("data-selected", "true");
        aa.scrollIntoView({ inline: 'center' });        
      }         
      map_web(getbuttonsweb)
    }

    if(getbuttonsflag.length >= 1){
      let yy = fiind(GetInstructions(storage["active.layer"]).default,getbuttonsflag)
      if(yy){
        yy.setAttribute("data-selected", "true");
        yy.scrollIntoView({ inline: 'center' });        
      }         
      map_flag(getbuttonsflag)
    }

  }  

  export {Modcolor,layerprocess,storage,fiind,map_remove};
  