import React, { Component } from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import Home                 from '../Pages/Home/Home';
import Login                from '../Pages/Login/Login';
import Builder              from '../Builder/builder';
import Footer_Builder       from '../Builder/Footer/footer'
import Footer_Bass          from '../Pages/Footer/footer';
import Menuo                from './menu'


class Menu extends Component {

  render() {
    return (
    <Router> 
      <Menuo />    
      <Route path="/Builder" exact component={Builder} />
      <Route path="/Builder" exact component={Footer_Builder} />
      <Route path="/Login" exact component={Login} />
      <Route path="/Login" exact component={Footer_Bass} />
      <Route path="/" exact component={Home} />
      <Route path="/" exact component={Footer_Bass} />      
    </Router>
    );
  }
}


export default Menu;