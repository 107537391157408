import {GetInstructions,Getordens} from '../assistants'
import {GetInfo} from '../workes'
import _ from 'lodash'
import spinner from '../Loader/Spinner/spinner';
import render from '../../Canvas/Render'
import {datacenter} from '../../Data/Datacenter'

  const findlayer = (lyr,data) => _.find(data, (i) => {
   return i.layer === lyr
  });


  const filtercor = async(Get,find) => {

    let ren;    
    if (find.lyr) {
      ren = await findlayer(find.lyr,Get.layers)
      ren = await render(ren,Get.conf)
    }

    return ren;
  }

  const filterweb = async(Get,find) => {

    let ren;    
    if (find.conect) {
      ren = await findlayer(find.conect,Get.layers)
      ren = await render(ren,Get.conf)
    }

    return ren;
  }

  const Insertcolor = (color,layer) => {
    return new Promise(resolve =>  {
     GetInstructions(layer).color = color;
     console.info("Se inserto el color")
     resolve()
    });
  }

  const Insertweb = (web,layer) => {
    return new Promise(resolve =>  {
     GetInstructions(layer).default = web;
     console.info("Se inserto la malla")
     resolve()
    });
  }  

  const Insertflag = (flag,layer) => {
    return new Promise(resolve =>  {
     GetInstructions(layer).default = flag;
     console.info("Se inserto la bandera")
     resolve()
    });
  } 

  const colormod = async (cor,layer) => {
    let rr;
    let Get = await GetInfo()  
    let find = await findlayer(layer,Get.layers)    
    rr = await Insertcolor(cor,layer)

    if(find) {
     rr = await filtercor(Get,find)
     rr = await render(find,Get.conf)
    }

    return rr
  }

  const webmod = async (web,layer) => {


    let rr;
    let Get = await GetInfo()  
    let find = await findlayer(layer,Get.layers)   
  

    rr = await spinner("on","Loader") 
    rr = await Insertweb(web,layer)
    if(find){
     rr = await filterweb(Get,find)    
     rr = await render(find,Get.conf)
    }
    rr = await console.info(datacenter) 
    rr = await spinner("off","Loader")  


    return rr
  }

  const flagmod = async (flag,layer) => {

    let rr;
    let Get = await GetInfo()  
    let find = await findlayer(layer,Get.layers)
     

    rr = await spinner("on","Loader") 
    rr = await Insertflag(flag,layer)    

    if(find) rr = await render(find,Get.conf);

    console.log(Getordens().conf)

    rr = await console.info(datacenter) 
    rr = await spinner("off","Loader")  

    return rr
  }     

  export {colormod,webmod,flagmod};