import createCanvas from '../Canvas/CreateCanvas';
import tmpcreateCanvas from '../Canvas/TmpCreateCanvas';
import {GetInstructions,GetProduct} from './assistants'

  const Size = cc => {

   let X = cc.X;  
   let Y = cc.Y;
 
   return {X,Y}    
  };

  const makeUrl = (url,img) => {
    return url + img + ".png";
  }

  const suplier = (cnf,other) => {
    let size   = Size(other);
    let base   = makeUrl(other.imgurl,other.render)
    let shadow = makeUrl(other.imgurl,cnf.img)
    let ctx    = createCanvas(cnf.layer,size,cnf.geo)
    let tmpCtx = tmpcreateCanvas(size);  

    return {size,base,shadow,ctx,tmpCtx}    
  } 

  const supliernon = (cnf,other) => {
    let size   = Size(other);
    let shadow = makeUrl(other.imgurl,cnf.img)
    let base   = makeUrl(other.imgurl,other.render)
    let ctx    = createCanvas(cnf.layer,size,cnf.geo)
    let tmpCtx = tmpcreateCanvas(size);  

    return {size,base,shadow,ctx,tmpCtx}    
  } 

  const suplierweb = (cnf,other,item) => {
    let size   = Size(other);
    let ctx    = createCanvas(item.layer,size,item.geo)
    let tmpCtx = tmpcreateCanvas(size);  

    return {size,ctx,tmpCtx}    
  }

  const GetInfo = _ => {
     let anngl = GetInstructions("Angle").default
     let conf = GetProduct(GetInstructions("Product").default);
     return conf["Angles"][anngl];  
  }  

export {Size,makeUrl,suplier,supliernon,suplierweb,GetInfo}