import {createMuiTheme } from '@material-ui/core/styles';
import yellow from '@material-ui/core/colors/yellow';
import teal from '@material-ui/core/colors/teal';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: yellow[700]
        },
        secondary: {
          main: teal[700]
        }
      },
  typography: {
    useNextVariants: true,
  },      
});

export default theme;