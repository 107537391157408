import Hammer from 'hammerjs'

  const scrollH = (e,div) => {
    e = window.event || e;
    let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
    let scrollAmount = 0;

    let slideTimer = setInterval(_ =>{  

      div.scrollLeft -= (delta * 14);
      scrollAmount += 14;
      if(scrollAmount >= 100){
         window.clearInterval(slideTimer);
      }
  
    }, 48);
  
  e.preventDefault();
  }


  const sideScroll = (div,direction,pull,animation) =>{
    let scrollAmount = 0;
    let slideTimer = setInterval(function(){
        if(direction === 'left'){
            div.scrollLeft -= pull;
        } else {
            div.scrollLeft += pull;
        }
        scrollAmount += pull;
        if(scrollAmount >= animation){
            window.clearInterval(slideTimer);
        }
    }, 28);
  } 

const ClickRightLeft = (scroll,item) => {
  let click = document.getElementById(item.target)
  if(click){
    let Bass = new Hammer(click)
    Bass.on(item.type, _ => sideScroll(scroll,item.direction,item.pull,item.animation));
  }
}

const Corslife = _ => {

    let scroll = document.getElementById("scrollmenu");

    [{target:"icon_Right",type:"tap",direction:"right",pull:14,animation:100},
     {target:"icon_Left",type:"tap",direction:"left",pull:14,animation:100},
     {target:"scrollmenu",type:"panright",direction:"left",pull:1,animation:15},
     {target:"scrollmenu",type:"panleft",direction:"right",pull:1,animation:15}
    ].map(item => ClickRightLeft(scroll,item));


    ['mousemove','mousewheel','DOMMouseScroll'].map(e => {
      let ret;
       if(scroll){
        ret = scroll.addEventListener(e, _ => scrollH(false,scroll));
       }
      return ret
    });	
}

export default Corslife;