const applyColor = (tmpCtx,image,color,Size) => {
  //tmpCtx.save()

  tmpCtx.fillStyle = color;
  tmpCtx.fillRect(0, 0, Size.X,Size.Y)
  tmpCtx.globalCompositeOperation = 'destination-in';
  tmpCtx.drawImage(image, 0, 0)

  //tmpCtx.restore()
  return tmpCtx.canvas
}

export default applyColor;