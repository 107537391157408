import {GetInstructions} from '../Builder/assistants'
import getImageDataFromImage from './GetImageDataFromImage';
import applyColor from './ApplyColor';
import blendShadow from './BlendShadow';
import ProviderDefaultColor from './GetColorDefault'

  const drawImage = async (lyr,imagebase,imageshadow,tmpCtx,size,ctx,type,color) => {
     return new Promise(resolve => {
       if (!color) {color = ProviderDefaultColor(lyr)}
       let shadowImageData = getImageDataFromImage(tmpCtx,imagebase,size)
       const colorCanvas = applyColor(tmpCtx,imageshadow,color,size)
  
       ctx.clearRect(0,0,size.X,size.Y);
       ctx.drawImage(colorCanvas, 0, 0)
       blendShadow(ctx, shadowImageData,size)
  
       resolve(type + ": " +[lyr])
     })
  
  }

export default drawImage;