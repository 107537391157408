import {GetInstructions,Getordens} from '../Builder/assistants';
import _ from 'lodash';

const FindOnAllColors = Color => _.find(Getordens().conf.Colors, (c) => {
    
    if(c.name === Color){
        return c.name === Color;
    }else{
        return c.color === Color;
    }

});

const ProviderDefaultColor = val => { 
    
    let ProviderLayerColor = GetInstructions(val);
    return FindOnAllColors(ProviderLayerColor.color).color;
    
}

export default ProviderDefaultColor;