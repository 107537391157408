import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {storage} from '../ButtonsModColor/default'
import {GetInstructions,GetProduct} from '../assistants'
import filteroptions from '../ButtonsModColor/Create_Colors'
import './Layered.css'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 324,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function VerticalTabs() {
  const classes = useStyles();
  
  const [value, setValue] = React.useState(storage["active.layer"]);
  const Get = val => GetInstructions(val);
  const [force, Setforce] = React.useState(false)

  if(force){
   filteroptions(value)
   console.info(value)
  }

  const handleChange = (event, newValue) => {
    storage["active.layer"] = event.currentTarget.name
    setValue(newValue);
    if(!force)Setforce(true)
  } 

  const Renderlayers = props => {
    const layers = props.layers;
    const listItems = layers.map((i) => {
      if(i.lyr){
       return <Tab name={i.lyr} className="color-module" key={i.id} value={i.id} label={i.name} />
      }else{
       return <Tab name={i.id} className="color-module" key={i.id} value={i.id} label={i.name} />
      }
    });
    return (
      <div className='layer_selector'>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className='scrollayermenu'
        >
          {listItems}
        </Tabs>
      </div>
    );    
  }

  return <Renderlayers layers={GetProduct(Get("Product").default)["Layers"]} />
  
}

export default VerticalTabs;