import React    from 'react';
import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Sidebar  from './Menu/Sidebar';
import Loader   from './Builder/Loader/loader';
import './index.css';

render(<Loader /> , document.getElementById('Cargando'));
render(<Sidebar />, document.getElementById('root'));

serviceWorker.unregister();