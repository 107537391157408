import React, { Component } from 'react';
import Logo                 from '../../logo.png';
import './loader.css';

class Loader extends Component {
	render() {
   let background = {
    backgroundImage: `url(${Logo})`
   }    
	 const html = (	
      <div id="pre" className="off">
       <div className="preloader-wrapper">
          <div style={background} className="logo_loader"></div> 
          <div className="lds-dual-ring"></div>
        </div>
      </div>	
      )
      return html	
	}
}

export default Loader;