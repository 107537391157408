  let Imgld = {};
  const ImgLoad = src => {
     return new Promise((resolve,reject) => {

         const cachedImage = Imgld[src]         
         if (cachedImage) {
           resolve(cachedImage)
         }else{
         const img = new Image();
         img.crossOrigin = "Anonymous";
         img.onload = () => {
             Imgld[src] = img;
             resolve(Imgld[src]);
         };
         img.src = src;
         //img.crossOrigin = "Anonymous";
       }
     });
  }

  export {Imgld,ImgLoad}